

body {
  margin: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  width:100% !important;

  object-fit: cover;
  object-position: center;
}

.sf-prosjektbilde img {
  height: 250px;
  border-radius:0px !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sf-hero-height {
  height: 72vh !important;
}

.sf-rel-top {
  position: relative;
  top:110px;
}

.sf-menufont {
  font-size: 16px !important;
}

.sf-footer {
  --min-height: 250px;
  background-image: url('https://www.adriaticinvest.no/assets/img/general/waves.jpg');
  background-size: cover;
}

.sf-auto {
  margin: auto;
  text-align: center;
}

.sf-mid-box {
  min-height: 250px;
  background-image: url('https://www.adriaticinvest.no/assets/img/general/bg_c.jpg');
  background-position: top;
  background-repeat: repeat;
  background-size: cover;
}


.sf-hero-overlay {
 opacity:0.8;
}

.sf-hero-active {
  
}

.sf-hero-bgtext {
  text-shadow: 2px 2px 0px #343a4050;
  padding: 5px;
  font-size:50px !important;

}

.smash-map-offset {
  margin-left:-1.5%;
}

.is-active .bar {
  background-color: #000;
}

.is-active .bar:nth-child(2) {
  transform: translateX(-100%);
}

.is-active .bar:nth-child(3) {
  transform: rotate(-45deg);
}

.is-active .bar:nth-child(4) {
  transform: rotate(45deg);
}



.sf-mid-box {
  min-height: 150px !important;
  background-image: url('https://www.adriaticinvest.no/assets/img/general/bg_c.jpg');
  background-position: top;
  background-repeat: repeat;
  background-size: cover;
}


.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.react-cookie-law-accept-all-btn {
  border-radius: 40px;
  padding:10px 25px 10px 25px !important;
  text-align: center !important;
  background-color: green !important;
  border: 0px;
}

.react-cookie-law-accept-selection-btn {
  border-radius: 40px;
  padding:10px 25px 10px 25px !important;
  text-align: center !important;
  background-color: black !important;
  border: 2px;
}

.react-cookie-law-message {
  font-size: 10px !important;
}


@media screen and (min-width: 993px) {
  .sf-navmargin {
    padding-left:10%;
    padding-right:10%;
  }
}



.text-info {
  color: #000 !important;
  font-weight:600;
}


.sf-bg-opacity {
  background-color: #00000069;
}

.sf-hover:hover {
  --border: 5px solid #0069d9;
  transition-duration: 500ms;
}
.sf-hover {
  border: 5px solid #FFF;
  box-shadow:
  1.3px 1.7px 5.3px rgba(0, 0, 0, 0.026),
  4.2px 5.6px 17.9px rgba(0, 0, 0, 0.038),
  19px 25px 80px rgba(0, 0, 0, 0.07)
;

}

.react-cookie-law-policy {
  display:none !important;
}

.sf-shadowbox {
  box-shadow:
  1.3px 1.7px 5.3px rgba(0, 0, 0, 0.026),
  4.2px 5.6px 17.9px rgba(0, 0, 0, 0.038),
  19px 25px 80px rgba(0, 0, 0, 0.07)
;
}

a:hover, a:focus { text-decoration: none;}



@media screen and (min-width: 993px) {
  .sf-padding-top-50 {
    padding-top:110px !important;
  }
  .sf-padding-bottom-50 {
    padding-top:110px !important;
  }

  .text-info {
    font-size: 55px !important;
    font-weight:normal !important;
  }
  .sf-prosjektbilde {
    height: 240px;
  }
}

@media screen and (max-width: 993px) {
  .sf-padding-top-50 {
    padding-top:50px !important;
  }
  .sf-prosjektbilde {
    height: 340px !important;
  }
}


.col-xl-6 {
  padding: 0;
  margin: 0;
}


.bg-light-gray {
  background-color:#f1f1f1;
}

.nav-link:hover {
  background-color:#f1f1f1;
  color:#007bff !important;
}

.nav-link.active {
  color: #007bff !important;
}

