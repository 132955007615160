/* Loading.css */
.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .loading-spinner {
    border: 4px solid #3498db;
    border-top: 4px solid transparent;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  .spin-complete {
    animation: none; /* Disable spinner animation when percentage reaches 100% */
  }
  
  .loading-text {
    margin-top: 20px;
    font-size: 16px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Your CSS file */
.fade-in {
    opacity: 0;
    animation: fade-in 1s ease-in-out forwards;
  }
  
  @keyframes fade-in {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  /* New keyframes for fading out the spinner */
  @keyframes fade-out-spinner {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }